import { api } from 'lib/api';
import { UrlBuilder } from 'utils/helpers/UrlBuilder';

import type { TChangePasswordResponse, TGetUserInfoResponse } from './types';

const urlBuilder = new UrlBuilder('api/v1');

export const userApi = {
    getUserInfo: async () => {
        const response = await api.get<TGetUserInfoResponse>(
            urlBuilder.build('user/info'),
        );

        return response.data;
    },

    logout: async () => {
        await api.post(urlBuilder.build('logout'));
    },

    changePassword: async () => {
        const response = await api.get<TChangePasswordResponse>(
            urlBuilder.build('user/change-password'),
        );

        return response.data;
    },
};
