import type { ThemeConfig } from 'antd';

export const THEME_CONFIG: ThemeConfig = {
    token: {
        colorPrimary: '#8958e4',
        fontFamily: 'Roboto',
        fontSizeHeading1: 38,
        fontSizeHeading2: 30,
        fontSizeHeading3: 24,
        fontSizeHeading4: 20,
        fontSizeHeading5: 16,
        fontSize: 16,
        controlHeight: 32,
        borderRadius: 2,
    },
    components: {
        Button: {
            borderRadius: 8,
            fontSize: 14,
            fontSizeLG: 16,
            borderRadiusLG: 8,
            borderRadiusSM: 8,
        },
        Input: {
            fontSize: 14,
        },
        InputNumber: {
            fontSize: 14,
        },
        Select: {
            fontSize: 14,
        },
        DatePicker: {
            fontSize: 14,
        },
    },
};
