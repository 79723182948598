import React, { useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Divider, Layout, Menu } from 'antd';
import cn from 'classnames';
import MenuItemContent from 'layouts/main/MenuItem/MenuItemContent';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import {
    SIDEBAR_ITEMS,
    SIDEBAR_WIDTH,
    SIDEBAR_WIDTH_COLLAPSED,
} from 'utils/constants/sidebar';
import type { TComponent } from 'utils/types/component';

import { UserBadge } from './components/UserBadge';
import styles from './LayoutMain.module.scss';

const { Header, Content, Sider } = Layout;

const style = {
    margin: 0,
    width: '100%',
    borderRadius: 0,
    height: '64px',
    fontSize: '14px',
    fontWeight: 500,
};

const LayoutMain: TComponent = () => {
    const { t } = useTranslation();

    const [collapsed, setCollapsed] = useState(false);

    return (
        <>
            <Header className={styles.header}>
                <div className={styles.logo}>
                    <span className={styles.logoUm}>Um.</span>money
                </div>
                <UserBadge />
            </Header>
            <Layout hasSider>
                <Sider
                    theme='light'
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    className={styles.sidebar}
                    width={collapsed ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}
                >
                    <div className={styles.sidebarContent}>
                        <Divider className={styles.divider} />
                        <Menu
                            rootClassName={styles.menuContainer}
                            className={styles.menu}
                            mode='inline'
                        >
                            {SIDEBAR_ITEMS.map(
                                ({ icon, to, title, subMenu }) =>
                                    subMenu ? (
                                        <Menu.SubMenu
                                            key={to}
                                            icon={icon}
                                            title={t(title)}
                                            className={styles.subMenu}
                                        >
                                            {subMenu.map((subItem) => (
                                                <Menu.Item
                                                    key={subItem.to}
                                                    className={styles.item}
                                                >
                                                    <MenuItemContent
                                                        to={subItem.to}
                                                        label={subItem.title}
                                                    />
                                                </Menu.Item>
                                            ))}
                                        </Menu.SubMenu>
                                    ) : (
                                        <Menu.Item key={to} style={style}>
                                            <MenuItemContent
                                                icon={icon}
                                                to={to}
                                                label={title}
                                            />
                                        </Menu.Item>
                                    ),
                            )}
                        </Menu>
                    </div>
                    <div
                        className={cn({
                            [styles.sidebarFooterCollapsed]: collapsed,
                            [styles.sidebarFooter]: !collapsed,
                        })}
                    >
                        {React.createElement(
                            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                            {
                                className: styles.trigger,
                                onClick: () => setCollapsed(!collapsed),
                            },
                        )}
                    </div>
                </Sider>
                <Layout>
                    <Content className={styles.content}>
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};

export { LayoutMain };
