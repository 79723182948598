export const MainRoutes = {
    home: '/',
    merchants: '/merchants',
    invoices: '/invoices',
} as const;

export type TMainRoutes = (typeof MainRoutes)[keyof typeof MainRoutes];
export type TMerchantSubRoutes =
    (typeof MerchantsSubRoutes)[keyof typeof MerchantsSubRoutes];

export const CreateEditSubRoutes = {
    create: 'create',
    edit: 'edit',
};

export const MerchantsSubRoutes = {
    Balance: 'balance',
    List: 'list',
    HistoryOfPayments: 'history-of-payments',
    HistoryOfSettlements: 'history-of-settlements',
    CreatePrepayment: 'create-prepayment',
    CreateSettlement: 'create-settlement',
};

export const InvoicesSubRoutes = {
    Payment: 'payment',
    Payout: 'payout',
};
