import type { IUserState } from './useUser';

const isLoading = (state: IUserState) => state.isLoading;
const userName = (state: IUserState) =>
    state.user?.name ?? 'tiwaj50333@polatrix.com';

const getUserInfo = (state: IUserState) => state.getUserInfo;
const logout = (state: IUserState) => state.logout;
const changePassword = (state: IUserState) => state.changePassword;

export const userSelectors = {
    isLoading,
    userName,

    getUserInfo,
    logout,
    changePassword,
};
