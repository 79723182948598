import type { AxiosError } from 'axios';
import type { IApiError } from 'utils/types/api';

const handleError = () => async (error: AxiosError<IApiError>) => {
    if (error?.response) {
        return Promise.reject(error.response.data);
    } else {
        console.error('Server did not respond, or request was blocked.', error);
        return Promise.reject(error);
    }
};

export { handleError };
