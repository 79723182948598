import { LayoutMain } from 'layouts/main';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
} from 'react-router-dom';
import {
    InvoicesSubRoutes,
    MainRoutes,
    MerchantsSubRoutes,
} from 'utils/constants/routes';
import { routesHelper } from 'utils/helpers/routesHelper';

import { AsyncPage } from 'components/utils/AsyncPage/AsyncPage';
import { ProtectedPage } from 'components/utils/ProtectedPage';

import { Pages } from 'models/Pages';

const balanceListRoute = routesHelper.join(
    MainRoutes.merchants,
    MerchantsSubRoutes.Balance,
    MerchantsSubRoutes.List,
);

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route
                element={
                    <ProtectedPage>
                        <LayoutMain />
                    </ProtectedPage>
                }
                path={MainRoutes.home}
            >
                <Route
                    path={routesHelper.getSubThreeRoute(MainRoutes.merchants)}
                >
                    <Route
                        path={routesHelper.getSubThreeRoute(
                            MerchantsSubRoutes.Balance,
                        )}
                        element={<AsyncPage page={Pages.Balances} />}
                    />
                </Route>
                <Route
                    path={routesHelper.getSubThreeRoute(MainRoutes.invoices)}
                >
                    <Route
                        path={routesHelper.getSubThreeRoute(
                            InvoicesSubRoutes.Payment,
                        )}
                        element={<AsyncPage page={Pages.PaymentInvoices} />}
                    />
                    <Route
                        path={routesHelper.getSubThreeRoute(
                            InvoicesSubRoutes.Payout,
                        )}
                        element={<AsyncPage page={Pages.PayoutInvoices} />}
                    />
                </Route>
                <Route element={<Navigate to={MainRoutes.home} />} path='*' />
                <Route
                    path={MainRoutes.home}
                    element={<Navigate to={balanceListRoute} />}
                />
            </Route>
        </Route>,
    ),
);

export default router;
