import React from 'react';
import { GatewayOutlined, LaptopOutlined } from '@ant-design/icons';
import type { ReactElement } from 'react';
import type { TMainRoutes, TMerchantSubRoutes } from 'utils/constants/routes';
import {
    InvoicesSubRoutes,
    MainRoutes,
    MerchantsSubRoutes,
} from 'utils/constants/routes';
import { routesHelper } from 'utils/helpers/routesHelper';
import type { TTKey } from 'utils/types/common';

const iconStyle = { fontSize: '22px', display: 'flex' };

export interface ISubMenu {
    to: TMerchantSubRoutes;
    title: TTKey;
}

interface ISidebarItem {
    to: TMainRoutes | TMerchantSubRoutes;
    title: TTKey;
    icon: ReactElement;
    subMenu?: ISubMenu[];
}

const SIDEBAR_ITEMS: ISidebarItem[] = [
    {
        title: 'common.sidebar.balance',
        icon: React.createElement(LaptopOutlined, { style: iconStyle }),
        to: routesHelper.join(MainRoutes.merchants, MerchantsSubRoutes.Balance),
    },
    {
        title: 'common.sidebar.invoices',
        icon: React.createElement(GatewayOutlined, { style: iconStyle }),
        to: MainRoutes.invoices,
        subMenu: [
            {
                to: routesHelper.join(
                    MainRoutes.invoices,
                    InvoicesSubRoutes.Payment,
                ),
                title: 'Инвойсы платежей',
            },
            {
                to: routesHelper.join(
                    MainRoutes.invoices,
                    InvoicesSubRoutes.Payout,
                ),
                title: 'Инвойсы выплаты',
            },
        ],
    },
];

const SIDEBAR_WIDTH = 248;
const SIDEBAR_WIDTH_COLLAPSED = 80;

export { SIDEBAR_ITEMS, SIDEBAR_WIDTH, SIDEBAR_WIDTH_COLLAPSED };

export type { ISidebarItem };
