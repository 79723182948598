import { SettingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styles from 'layouts/main/LayoutMain.module.scss';
import { userSelectors } from 'store/user/userSelectors';
import { useUser } from 'store/user/useUser';
import type { TComponent } from 'utils/types/component';

export const ChangePasswordButton: TComponent = () => {
    const changePassword = useUser(userSelectors.changePassword);

    return (
        <Button
            onClick={changePassword}
            className={styles.menuItemButtonContainer}
        >
            <SettingOutlined />
            <span className={styles.text}>Изменить пароль</span>
        </Button>
    );
};
