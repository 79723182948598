import { Menu } from 'antd';
import { userSelectors } from 'store/user/userSelectors';
import { useUser } from 'store/user/useUser';
import { UserBadge as UserBadgeUI } from 'ui/UserBadge';

import { ChangePasswordButton } from './components/ChangePasswordButton';
import { LogoutButton } from './components/LogoutButton';

export const UserBadge = () => {
    const username = useUser(userSelectors.userName);

    return (
        <UserBadgeUI username={username}>
            <Menu>
                <Menu.Item key='change-password'>
                    <ChangePasswordButton />
                </Menu.Item>
                <Menu.Item key='logout'>
                    <LogoutButton />
                </Menu.Item>
            </Menu>
        </UserBadgeUI>
    );
};
