import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styles from 'layouts/main/LayoutMain.module.scss';
import { userSelectors } from 'store/user/userSelectors';
import { useUser } from 'store/user/useUser';
import type { TComponent } from 'utils/types/component';

export const LogoutButton: TComponent = () => {
    const logout = useUser(userSelectors.logout);

    return (
        <Button onClick={logout} className={styles.menuItemButtonContainer}>
            <LogoutOutlined className={styles.logoutIcon} />
            <span className={styles.logoutText}>Выйти из аккаунта</span>
        </Button>
    );
};
