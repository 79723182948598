import { CreateEditSubRoutes } from 'utils/constants/routes';

const join = (...paths: string[]): string => {
    return paths.join('/');
};

export const routesHelper = {
    join,
    getLastUrlPath: (url: string) => {
        return url.split('/').pop();
    },

    editRoute: join(CreateEditSubRoutes.edit, ':id'),
    createRoute: CreateEditSubRoutes.create,

    getSubThreeRoute: (url: string) => `${url}/*`,
    getAbsoluteRoute: (url: string) => `/${url}`,
};
