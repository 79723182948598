import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { TTKey } from 'utils/types/common';
import type { TComponent } from 'utils/types/component';

import styles from './MenuItemContent.module.scss';

interface IMenuItemContentProps {
    to: string;
    label: TTKey;
    icon?: ReactElement;
}

const MenuItemContent: TComponent<IMenuItemContentProps> = (props) => {
    const { to, label, icon } = props;

    const { t } = useTranslation();

    return (
        <Link to={to} className={styles.link}>
            {icon}
            <span className={styles.label}>{t(label)}</span>
        </Link>
    );
};

export default MenuItemContent;
