import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { TRANSLATION } from 'utils/types/common';

type TManifest = { [key: string]: string };

type TLoadPathOption =
    | string
    | ((lngs: string[], namespaces: string[]) => string)
    | ((lngs: string[], namespaces: string[]) => Promise<string>);

let manifest: TManifest | null = null;
let manifestLoadingPromise: Promise<void> | null = null;

const DEFAULT_NS_PATH = 'translations.json';
const DEFAULT_LNG = 'en';
const MANIFEST_PATH = 'manifest.json';
const LOCALES = 'locales';
const JSON_EXT = 'json';

const baseUrl = window.location.origin;

async function loadManifest(): Promise<void> {
    if (manifest) {
        return;
    }

    if (!manifestLoadingPromise) {
        manifestLoadingPromise = (async () => {
            try {
                // Добавление метки времени к URL манифеста для предотвращения кэширования
                const currentTimeStamp = new Date().getTime();

                const manifestUrl = `${baseUrl}/${MANIFEST_PATH}?ts=${currentTimeStamp}`;
                const response = await fetch(manifestUrl);
                manifest = (await response.json()) as TManifest;
            } catch (error) {
                console.error(error);
                manifest = {};
            }
        })();
    }

    await manifestLoadingPromise;
    manifestLoadingPromise = null;
}

const loadPath: TLoadPathOption = async (lngs, namespaces) => {
    try {
        await loadManifest();
    } catch (error) {
        console.error(error);
        return `${baseUrl}/${LOCALES}/${DEFAULT_LNG}/${DEFAULT_NS_PATH}`;
    }

    const lng = lngs[0];
    const ns = namespaces[0];
    const manifestKey = `${lng}/${ns}.${JSON_EXT}`;

    return manifest && manifest[manifestKey]
        ? `${baseUrl}/${LOCALES}/${lng}/${manifest[manifestKey]}`
        : `${baseUrl}/${LOCALES}/${lng}/${DEFAULT_NS_PATH}`;
};

i18n.use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        backend: {
            loadPath,
        },
        detection: {
            order: ['navigator'],
        },
        returnNull: false,
        load: 'languageOnly',
        fallbackLng: TRANSLATION.EN,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
